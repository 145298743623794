<template>
  <div class="tableList">
    <list-search :childData="childData" @onSearch="search"  @clearSearch="search"></list-search>
     <global-table ref="addDetailtable" :row-key='returnrowkey' :tableField="tableField"  :tableData="tableData"></global-table>
  </div>
</template>

<script>
import ListSearch from '@/components/actionList/ListSearch.vue'
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import { request, deepClone } from '@/assets/js/http.js'
import { SearchList, SearchData, tableField, tableData } from './js/createaplanPayreqdialog'
export default {
  name: 'StatementDetaildialog',
  components: { ListSearch, GlobalTable },
  props: ['defaultSearchData'],
  data () {
    return {
      tableField: tableField,
      tableData: tableData,
      searchdata: this.defaultSearchData,
      pageNum: 1,
      pageSize: 10,
      childData: { searchList: SearchList, searchData: deepClone(SearchData) }
    }
  },
  mounted () {
    this.addDetailList()
  },
  methods: {
    returnrowkey (row) {
      return JSON.stringify(row)
    },
    addDetailList () { // 提交创建预付款申请单
      request('/api/order/order/getOrderList', 'get', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          const tempData = Response.data
          for (const item of tempData) {
            item.noTaxPrice = parseFloat(item.noTaxPrice)
            item.orderNum = parseFloat(item.orderNum)
            item.taxTotalPrice = parseFloat(item.taxTotalPrice)
            item.taxPrice = parseFloat(item.taxPrice)
          }
          this.tableData = tempData
        }
      })
    },
    search: function (data) {
      data.pageNum = this.pageNum
      data.pageSize = this.pageSize
      data.company = this.defaultSearchData.company
      data.supplier = this.defaultSearchData.supplier
      this.searchdata = { ...data }
      request('/api/order/order/getOrderList', 'get', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          const tempData = Response.data
          for (const item of tempData) {
            item.noTaxPrice = parseFloat(item.noTaxPrice)
            item.orderNum = parseFloat(item.orderNum)
            item.taxTotalPrice = parseFloat(item.taxTotalPrice)
            item.taxPrice = parseFloat(item.taxPrice)
          }
          this.tableData = tempData
        }
      })
    }
  }

}
</script>
<style scoped>
.tableList{
padding: 0px;
}
</style>
