export var SearchList = [
  { labe: '采购单编号', code: 'orderCode', type: 'input' },
  { labe: '物料编号', code: 'materialCode', type: 'input' }
]
export var SearchData = {
  orderCode: '',
  materialCode: ''
}
export var tableField = [
  { selectType: 'selection', width: '', type: 'input', reserveSelection: true },
  { label: '采购单编号', code: 'orderCode', type: 'input', width: '130' },
  { label: '采购单行号', code: 'rowProject', type: 'input', width: '130' },
  { label: '物料编号', code: 'materialCode', type: 'input', width: '' },
  { label: '物料描述', code: 'materialDescribe', type: 'input', width: '' },
  { label: '订单数量', code: 'orderNum', type: 'input', width: '' },
  { label: '单价', code: 'taxPrice', type: 'amount', align: 'right', width: '' },
  { label: '含税总额', code: 'taxTotalPrice', type: 'amount', align: 'right', width: '' }
]
export var tableData = []
